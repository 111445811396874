import React from 'react';
import classes from './Fab.module.scss';

const Fab = ({children, onClick, title, floating=true}:Props) =>{

    return(
        <>
            <div className={floating? classes.FabContainerFloating : classes.FabContainer} onClick={onClick}>
                <div className={classes.Fab}>{children}</div>
            </div>
            <div className={classes.FabTitle}>{title && <div>{title}</div>}</div>
        </>
    )
}

interface Props{
    children: JSX.Element,
    onClick: Function,
    title: string,
    floating: boolean
}

export default Fab;