import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../05_redux/store';
import { HomeworkEntity } from '../../06_utils/db/entity/homeworkEntity ';
import { Col, Page, Row } from 'react-onsenui';
import classes from './HomeworkDetail.module.scss';
import Wave from '../../01_atoms/Wave';
import TopBar from '../../01_atoms/TopBar';
import Fab from '../../01_atoms/Fab';
import { FaPlus } from 'react-icons/fa';
import Post from '../Post/Post';
import { useTranslation } from 'react-i18next';

const HomeworkDetail = () =>{
    const {t} = useTranslation()
    const navigate = useNavigate();
    const homeworks:HomeworkEntity[] = useSelector((root:RootState)=>root.homeworkSlice.homeworkStore.homeworks);
    const { id } = useParams();

    const homework = homeworks.find(h=>h.id === Number(id));

    const back = () =>{
        navigate('/homework/');
    }

    const createNewPost = () =>{
        navigate('/newpost/'+homework?.id);
    }

    return(
        <Page>
            <TopBar fun={back} title={homework?.title}/> 
            <Wave invert/>
            <div className={classes.HomeworkDetail}>
                <Row>
                    <Col className={classes.HomeworkDetailDescr}>
                        {homework?.descr}
                    </Col>
                </Row>
                <Row className={classes.HomeworkDetailPosts}>
                    <Col>
                        {homework?.post?.length === 0 && t("homeworkDetail.noPosts")}                    
                        {homework?.post?.map((pfh, i)=><Post key={i} post={pfh} editable={false}/>)}
                    </Col>
                </Row>
                <Row className={classes.HomeworkDetailButtons}>
                    <Col className={classes.HomeworkDetailButton}>
                        <Fab id="goto-add-post-from-homework--btn" onClick={createNewPost}title={t('homeworkDetail.addPost')} floating={false}><FaPlus/></Fab>
                    </Col>
                </Row>
            </div>
        </Page>
    )
}

export default HomeworkDetail;