import React from 'react';
import classes from './Wave.module.scss';

const Wave = ({invert=false}:Props) =>{

    const style = invert ? classes.InvertColor: classes.RegularColor;

    return (	
        <div className={classes.WaveContainer}>
            {
                invert ? 				
                    <svg className={style} width="100%" height="20%" viewBox="0 0 500 2000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" overflow="auto" shapeRendering="auto">
                        <defs>
                            <path id="wavepath" d="M 0 2000 0 500 Q 150 86 300 500 t 300 0 300 0 v+2000 z" /> 
                        </defs>
                        <g >
                            <use xlinkHref="#wavepath" y="97" >
                        </use>
                        </g>
                    </svg>
                :				
                    <svg className={style} width="100%" height="20%" viewBox="0 0 500 1000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" overflow="auto" shapeRendering="auto">
                        <defs>
                            <path id="wavepath" d="M 0 -1000 0 500 Q 150 86 300 500 t 300 0 300 0 v-1000 z" /> 
                        </defs>
                        <g >
                            <use xlinkHref="#wavepath" y="97" >
                        </use>
                        </g>
                    </svg>
            
            }
        </div>
    )
}

interface Props{
    invert: boolean,
}

export default Wave;