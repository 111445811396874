import React from 'react';
import classes from './Pagination.module.scss';
import { FaRegDotCircle } from 'react-icons/fa';
import { FaRegCircle } from 'react-icons/fa6';

const Pagination = ({progress, max, setPage}:Props) =>{

    const onClick = (i) => {
        setPage(i)
    }

    return(
        <div className={classes.paginationContainer}>
            {Array(max).fill(0).map((v,i)=>(
                <span key={i} className={classes.paginationMark} onClick={()=>onClick(i)}>
                    {i===((max-1)*progress) ? <FaRegDotCircle/> : <FaRegCircle/>}
                </span>
            ))}
        </div>
    )

}

interface Props{
    progress: number,
    max: number
    setPage: Function
}

export default Pagination;