import "reflect-metadata";
import React, { useCallback, useEffect } from 'react';
import { Preferences } from '@capacitor/preferences';
import './App.scss';
import { useSelector } from 'react-redux';
import store, { RootState } from '../05_redux/store';
import { logout, setUser, validate, setPin } from '../05_redux/StatusSlice';
import { MemoryRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from '../04_pages/Login';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen';
import Logout from '../04_pages/Logout';
import Wall from '../04_pages/Wall';
import Main from '../04_pages/Main';
import HomeWork from '../04_pages/HomeWorks';
import NewHomeWork from '../04_pages/NewHomeWork';
import SelectKid from '../04_pages/SelectKid';
import ChartEmo from '../04_pages/ChartEmo';
import Chat from '../04_pages/Chat';
import { startLiveQuery } from '../05_redux/ChatSlice';
import { UserType } from './types';
import { FaChalkboard, FaClipboardCheck, FaCommentDots, FaExchangeAlt, FaRegChartBar, FaSignOutAlt } from 'react-icons/fa';
import { syncHomeworks } from "../05_redux/HomeworkSlice";
import { syncPosts } from "../05_redux/PostSlice";
import Options from "../04_pages/Options";
import i18n from "i18next";
import i18nFile from "../00_assets/i18n.json";
import { useTranslation, initReactI18next } from "react-i18next";
import NewPostWizard from "../03_organisms/NewPostWizard/NewPostWizard";
import NewHomeWorkWizard from "../03_organisms/NewHomeworkWizard/NewHomeworkWizard";
import HomeworkDetail from "../03_organisms/Homework/HomeworkDetail";
import PostDetail from "../03_organisms/Post/PostDetail";
import EditPostWizard from "../03_organisms/NewPostWizard/EditPostWizard";


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: i18nFile,
    lng: "pl", // if you're using a language detector, do not define the lng option
    fallbackLng: "pl",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const App = () =>{
  const dispatch = store.dispatch;
  const selectedKid = useSelector((root:RootState)=>root.statusSlice.status.selectedKid);
  const availableKids:UserType[] = useSelector((root:RootState)=>root.statusSlice.status.availableKids);
  const status = useSelector((root:RootState)=>root.statusSlice.status);
  const user:UserType = status.user as UserType;
  const {t} = useTranslation()

  let disableMenu = !user;

  const syncData = useCallback((user) =>{
   if(status.isOnline){
     dispatch(syncHomeworks(user)).then((res:any)=>{
       dispatch(syncPosts(user));
     })
   }
  },[status.isOnline, dispatch])

  useEffect(()=>{
    Preferences.get({ key: 'pin' }).then(pin=>{
      dispatch(setPin(pin.value))
    });
  },[dispatch])

  useEffect(()=>{
    if(user?.objectId){
      syncData(selectedKid || user);
    }else{
      if(status.isOnline){
        Preferences.get({ key: 'user' }).then(userStr=>{
          const user = JSON.parse(userStr.value ||"{}");     
          if(user?.objectId){
            dispatch(setUser(user))
          }
        });
      }
    }
  },[user, selectedKid, dispatch, status.isOnline, syncData]);

  useEffect(()=>{
    AndroidFullScreen.isImmersiveModeSupported()
    .then(() => AndroidFullScreen.immersiveMode())
    .catch(console.warn);
  },[])

  useEffect(()=>{
    if(status.isOnline){
      if(user?.type === 'kid'){
        dispatch(startLiveQuery(user.objectId, [user.therapist?.objectId]));
      }else if(user?.type === 'parent'){
        dispatch(startLiveQuery(user.objectId, [user.therapist?.objectId]));
      }else if(user?.type === 'terap'){
        if(availableKids.length>0){
          dispatch(startLiveQuery(user.objectId, availableKids.map(ak=>ak.objectId)));
        }
      }
    }
  }, [availableKids, dispatch, status.isOnline, user.objectId, user.therapist?.objectId, user.type])

  if(user){
    if(status.isOnline){
      dispatch(validate()).catch(err=>{
        dispatch(logout());
      })
    }
  }
  
 
  const getRoutes = () =>{
    if(status.pin && user && user.type === "kid"){
      const menu = [
        {id:'wall', label:t('menu.wall'), href:"/", icon:<FaChalkboard/>},
        {id:'homework', label:t('menu.homework'), href:"/homework", icon:<FaClipboardCheck/>},
        // {id:'emotionChart', label:t('menu.emotionChart'), href:"/chart", icon:<FaRegChartBar/>},
        status.isOnline && {id:'chat', label:t('menu.chat'), href:"/chat", icon:<FaCommentDots/>},
        {id:'logout', label:t('menu.logout'), href:"/logout", icon:<FaSignOutAlt/>}
      ].filter(m=>m);
      return (
        <Route path="/" element={<Layout menu={menu} disableMenu={disableMenu} user={user}/>}>
          <Route index element={<Main idx={0}/>} />
          <Route path="newpost" element={<NewPostWizard />} />
          <Route path="/newpost/:homeworkId" element={<NewPostWizard/>} />
          <Route path="/editpost/:postId" element={<EditPostWizard/>} />
          <Route path="/post/:id" element={<PostDetail />}/>
          <Route path="homework" element={<Main idx={1} />} />
          <Route path="newhomework" element={<NewHomeWorkWizard />} />
          <Route path="/homework/:id" element={<HomeworkDetail />}/>
          <Route path="chart" element={<ChartEmo />} />
          <Route path="chat" element={<Chat />} />
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<Main />} />
        </Route>
      )
    }else if(user && user.type === "parent"){
      const menu = [
        {id:'homework', label:t('menu.homework'), href:"/homework", icon:<FaClipboardCheck/>},
        {id:'chat', label:t('menu.chat'), href:"/chat", icon:<FaCommentDots/>},
        {id:'logout', label:t('menu.logout'), href:"/logout", icon:<FaSignOutAlt/>}
      ]
      return (
        <Route path="/" element={<Layout menu={menu} disableMenu={disableMenu}/>}>
          <Route index element={<HomeWork />} />
          <Route path="homework" element={<HomeWork />} />
          <Route path="chat" element={<Chat />} />
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<HomeWork />} />
        </Route>
      )
    }else if(user && user.type === "terap"){
      if(selectedKid){
        const menu = [
          {id:'selectPatient', label:t("menu.selectPatient"), href:"/", icon:<FaExchangeAlt/>},
          {id:'patientWall', label:t("menu.patientWall"), href:"/wall", icon:<FaChalkboard/>},
          {id:'patientHomeworks', label:t("menu.patientHomeworks"), href:"/homework", icon:<FaClipboardCheck/>},
          {id:'patientChart', label:t("menu.patientChart"), href:"/chart", icon:<FaRegChartBar/>},
          {id:'patientChat', label:t("menu.patientChat"), href:"/patientchat", icon:<FaCommentDots/>},
          {id:'parentChat', label:t("menu.parentChat"), href:"/parentchat", icon:<FaCommentDots/>},
          {id:'logout', label:t('menu.logout'), href:"/logout", icon:<FaSignOutAlt/>}
        ]
        return (
          <Route path="/" element={<Layout menu={menu} disableMenu={disableMenu} user={selectedKid}/>}>
            <Route index element={<SelectKid />} />
            <Route path="wall" element={<Wall selectedKid={selectedKid}/>} />
            <Route path="homework" element={<HomeWork selectedKid={selectedKid} />} />
            <Route path="newhomework" element={<NewHomeWork />} />
            <Route path="chart" element={<ChartEmo />} />
            <Route path="patientchat" element={<Chat selectedKid={selectedKid}/>} />
            <Route path="parentchat" element={<Chat slectedParent={selectedKid.parent?.objectId}/>} />
            <Route path="logout" element={<Logout />} />
            <Route path="*" element={<SelectKid />} />
          </Route>
        )
      }else{
        const menu = [
          {id:'selectPatient', label:t("menu.selectPatient"), href:"/", icon:<FaExchangeAlt/>},
          {id:'logout', label:t('menu.logout'), href:"/logout", icon:<FaSignOutAlt/>}
        ]
        return (
          <Route path="/" element={<Layout menu={menu} disableMenu={disableMenu}/>}>
            <Route index element={<SelectKid />} />
            <Route path="logout" element={<Logout />} />
            <Route path="*" element={<SelectKid />} />
          </Route>
        )
      }
    }else{
      if(status.pin){
        return (
          <Route path="/" element={<Layout menu={[]} disableMenu={disableMenu}/>}>
            <Route index element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="*" element={<Login />} />
          </Route>
        )  
      }else{
        return (
          <Route path="/" element={<Layout menu={[]} disableMenu={disableMenu}/>}>
            <Route index element={<Options />} />
            <Route path="*" element={<Options />} />
          </Route>
        )
      }
    }
  }

  return (
    <MemoryRouter>
      <Routes>
          { getRoutes() }
      </Routes>
    </MemoryRouter>
  );
}

export default App;
