import { createSlice } from '@reduxjs/toolkit';
import Redux from 'redux';
import api from '../app/api';
import { PostType, UserType } from '../app/types';
import store from './store';
import PostDataSource from '../06_utils/db/data-sources/PostDataSource';
import { PostEntity } from '../06_utils/db/entity/postEntity';
import { Capacitor } from '@capacitor/core';
import sqliteConnection from '../06_utils/db/database';
import { setLoading } from './StatusSlice';
import { mergePosts, post2PostType, posts2PostType } from '../06_utils/mergePosts';

export const PostSlice = createSlice({
  name: 'userData',
  initialState: {
    postStore: {
      posts:[] as PostEntity[]
    },
  },
  reducers: {
    addPost: ({postStore}, {payload}) => {
      postStore.posts.unshift(payload);
    },
    updatePostReducer: ({postStore}, {payload}) => {
      const i = postStore.posts.findIndex(p=>p.id === payload.id)
      postStore.posts[i] = payload;
    },
    setPosts: ({postStore}, {payload}) => {
      postStore.posts = payload;
    },
    changeACL: ({postStore}, {payload}) => {
      const post  = postStore.posts.find(p=>p.objectId===payload.objectId)
      if(post){ post.ACL = payload.ACL; }
    },
  },
})

export const syncPosts = (selectedKid?:UserType)=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      dispatch(setLoading(true));
      const userObjectId=store.getState().statusSlice.status.user.objectId;

      const connection = PostDataSource;
      const localPosts:PostEntity[] = await connection
        .createQueryBuilder(PostEntity, 'post')
        .leftJoinAndSelect("post.images", "image")
        .leftJoinAndSelect("post.records", "record")
        .leftJoinAndSelect("post.homework", "homework")
        .orderBy('post.postDate')
        .getMany();

      const where = selectedKid ? '&where={"owner":{"__type":"Pointer","className":"_User", "objectId":"'+selectedKid.objectId+'"}}' : "";
      const remotePosts:PostType[] = (await api.get('/classes/Post?order=-createdAt'+where)).data.results;

      const newLocalPosts = await mergePosts(localPosts, remotePosts, userObjectId, dispatch);

      const newPosts = [...posts2PostType(localPosts), ...newLocalPosts].sort((a,b) => new Date(b.postDate!).getTime() - new Date(a.postDate!).getTime() )
      dispatch(setPosts(newPosts))
      dispatch(setLoading(false));
    });
  }
}

export const requestPosts = (selectedKid?:UserType)=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      dispatch(setLoading(true));

      const connection = PostDataSource;
      const localPosts:PostEntity[] = await connection
        .createQueryBuilder(PostEntity, 'post')
        .leftJoinAndSelect("post.images", "image")
        .leftJoinAndSelect("post.records", "record")
        .leftJoinAndSelect("post.homework", "homework")
        .orderBy('post.postDate')
        .getMany();
        
      const newPosts = [...posts2PostType(localPosts)].sort((a,b) => new Date(b.postDate!).getTime() - new Date(a.postDate!).getTime() )
      dispatch(setPosts(newPosts))
      dispatch(setLoading(false));
    });
  }
}

export const newPost = (newPost:PostType) =>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      dispatch(setLoading(true));
      const userObjectId=store.getState().statusSlice.status.user.objectId;

      const connection = PostDataSource;
      const database = connection.options.database;
      const post:PostEntity = new PostEntity(newPost, store.getState().homeworkSlice.homeworkStore.homeworks);
      const postRepository = connection.getRepository(PostEntity);

      let savedPost:PostEntity = await postRepository.save(post);
      if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
        sqliteConnection.saveToStore(database);
      }
      if(store.getState().statusSlice.status.isOnline){
        await mergePosts([savedPost], [], userObjectId, dispatch);
      }
      savedPost = await postRepository.findOne({ where: { id: savedPost.id } }) || savedPost;
      dispatch(addPost(post2PostType(savedPost)));
      dispatch(setLoading(false));
      resolve(savedPost);
    });
  }
}

export const updatePost = (newPost:PostType) =>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      dispatch(setLoading(true));
      const userObjectId=store.getState().statusSlice.status.user.objectId;

      const connection = PostDataSource;
      const database = connection.options.database;
      const post:PostEntity = new PostEntity(newPost, store.getState().homeworkSlice.homeworkStore.homeworks);
      const postRepository = connection.getRepository(PostEntity);

      await postRepository.update(newPost.id, {...post});
      if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
        sqliteConnection.saveToStore(database);
      }
      if(store.getState().statusSlice.status.isOnline){
        await mergePosts([post], [], userObjectId, dispatch);
      }
      const savedPost = await postRepository.findOne({ where: { id: post.id } }) || post;

      dispatch(updatePostReducer(post2PostType(savedPost)));
      dispatch(setLoading(false));
      resolve(savedPost);
    });
  }
}

export const sharePost = (newPost:PostType, share:boolean) =>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise((resolve) =>{
      const objectId = newPost.objectId
      const ACL = {}
      ACL[store.getState().statusSlice.status.user.objectId] = { "read": true, "write": true }
      if(share){ ACL[store.getState().statusSlice.status.user.therapist.objectId] = { "read": true, "write": false }; }
      api.put('/classes/Post/'+objectId, {ACL}).then((response:any) =>{
        dispatch(changeACL({objectId, ACL}));
        resolve(response.data);
        return response.data;
      }).catch((error:any) =>{
        console.log(error)
        dispatch(setPosts(error))
        return error;
      });
    });
  }
}


export const { addPost, updatePostReducer, changeACL, setPosts } = PostSlice.actions

export default PostSlice.reducer
